import each from 'lodash/each';
import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError } from '@/store/setError';
import Create from './PlanningCreateModule';
import Edit from './PlanningEditModule';

const { getPlanningVehicleLocations, postVehicleBlocks, postDedicatedHours } = backOffice.planning;
const { postPlanningBookings, postBookingBlocks } = backOffice.bookings;

export const scopes = {
  vehicleLocations: 'vehicleLocations',
  bookings: 'bookings',
  vehicleBlocks: 'vehicleBlocks',
  bookingBlocks: 'bookingBlocks',
  dedicatedHours: 'dedicatedHours',
};
export const parseVehicleLocations = ({
  vehicleLocations,
  resourceLocationTransformer,
  resourceVehicleTransformer,
  canViewVehicleLink,
}) => {
  const categoriesMap = new Map();
  const locationsOptions = [];
  const vehicleLocationsTuples = [];
  const resources = [];
  each(vehicleLocations, (location, locationUuid) => {
    const locationModel = {
      uuid: locationUuid,
      name: location.name,
      isOneWayAllowed: location.one_way_allowed,
    };
    locationsOptions.push(locationModel);
    const locationResource = resourceLocationTransformer(locationModel);

    each(location.vehicles, (vehicle, vehicleUuid) => {
      const vehicleResource = resourceVehicleTransformer(
        vehicle,
        vehicleUuid,
        locationUuid,
        canViewVehicleLink,
      );
      if (!categoriesMap.has(vehicle.category_id)) {
        const category = {
          id: vehicle.category_id,
          uuid: vehicle.category_uuid,
          name: vehicle.category_name,
        };
        categoriesMap.set(vehicle.category_id, category);
      }

      locationResource.children.push(vehicleResource);
      vehicleLocationsTuples.push({
        location_uuid: locationUuid,
        vehicle_uuid: vehicleUuid,
      });
    });

    resources.push(locationResource);
  });
  return {
    categoriesOptions: [...categoriesMap.values()],
    locationsOptions,
    resources,
    vehicleLocationsTuples,
  };
};

const planningModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    setError,
    resetData(state) {
      state[scopes.vehicleLocations].STATUS = { ...INITIAL_STATUS };
      state[scopes.bookings].STATUS = { ...INITIAL_STATUS };
      state[scopes.vehicleBlocks].STATUS = { ...INITIAL_STATUS };
      state[scopes.bookingBlocks].STATUS = { ...INITIAL_STATUS };
      state[scopes.vehicleLocations].data = [];
      state[scopes.bookings].data = null;
      state[scopes.vehicleBlocks].data = null;
      state[scopes.bookingBlocks].data = null;
    },
  },
  actions: {
    getPlanningVehicleLocations({ commit }, params) {
      return runAsyncFlow(commit, {
        request: getPlanningVehicleLocations,
        params: [params, 'v2'],
        scope: scopes.vehicleLocations,
      });
    },
    postPlanningBookings({ commit }, { request }) {
      runAsyncFlow(commit, {
        request: postPlanningBookings,
        params: [request],
        scope: scopes.bookings,
      });
    },
    postVehicleBlocks({ commit }, { request }) {
      runAsyncFlow(commit, {
        request: postVehicleBlocks,
        params: [request],
        scope: scopes.vehicleBlocks,
      });
    },
    postBookingBlocks({ commit }, { request }) {
      runAsyncFlow(commit, {
        request: postBookingBlocks,
        params: [request],
        scope: scopes.bookingBlocks,
      });
    },
    postDedicatedHours({ commit }, { request }) {
      runAsyncFlow(commit, {
        request: postDedicatedHours,
        params: [request],
        scope: scopes.dedicatedHours,
      });
    },
  },
})));

const planning = {
  ...planningModel,
  modules: { Create, Edit },
};

export default planning;
