import concat from 'lodash/concat';
import each from 'lodash/each';
import omit from 'lodash/omit';
import set from 'lodash/set';
import {
  INITIAL_STATUS,
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { BOOKING_ACTIONS } from '@domains/Carsharing/Planning/components/BookingSetup/components/const/bookingActions';

const {
  getEditBooking,
  putEditBooking,
  patchActivateBooking,
  patchResendBooking,
  patchCancelBooking,
  postBookingBypassCode,
  patchTerminateBooking,
  patchTerminateByAdminBooking,
  patchForceFinishBooking,
  patchFinishUnavailability,
} = backOffice.bookings;

const {
  updateUnavailability,
  deleteUnavailability,
} = backOffice.availability;

export const scopes = {
  editBooking: 'booking',
  bypassCode: 'bypassCode',
  startingActions: 'startingActions',
  finishingActions: 'finishingActions',
  cancellingActions: 'cancellingActions',
  bookingActionsFeedback: 'bookingActionsFeedback',
  forceFinish: 'forceFinish',
};

const planningEditModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    resetBookingData(state) {
      state[scopes.editBooking].data = null;
      state[scopes.editBooking].error = null;
      state.error = null;
    },
    clearEditBookingError(state) {
      state.error = null;
      state[scopes.editBooking].error = null;
    },
    setBookingActionFeedback(state, feedback) {
      state[scopes.bookingActionsFeedback].data = feedback;
    },
    resetBookingActions(state, pathsToOmit = [scopes.bypassCode]) {
      if (state[scopes.bypassCode].STATUS.ERROR) {
        set(pathsToOmit, 'length', 0);
      }
      const actionsScopes = omit(scopes, concat(['editBooking', 'unavailability'], pathsToOmit));
      each(actionsScopes, scope => {
        state[scope].data = [];
        state[scope].error = null;
        state[scope].STATUS = { ...INITIAL_STATUS };
      });
    },
  },
  actions: {
    async getEditBooking({ commit }, bookingUuid) {
      commit('resetBookingActions', []);
      await runAsyncFlow(commit, {
        request: getEditBooking,
        params: [bookingUuid],
        scope: scopes.editBooking,
      });
    },
    async putEditBooking({ commit }, { bookingUuid, data }) {
      await runAsyncFlow(commit, {
        request: putEditBooking,
        params: [bookingUuid, data],
      });
    },
    async patchActivateBooking({ commit }, bookingUuid) {
      commit('resetBookingActions');
      await runAsyncFlow(commit, {
        request: patchActivateBooking,
        params: [bookingUuid],
        scope: scopes.startingActions,
      });

      commit('setBookingActionFeedback', BOOKING_ACTIONS.activateBooking);
    },
    async patchResendBooking({ commit }, bookingUuid) {
      commit('resetBookingActions');
      await runAsyncFlow(commit, {
        request: patchResendBooking,
        params: [bookingUuid],
        scope: scopes.startingActions,
      });

      commit('setBookingActionFeedback', BOOKING_ACTIONS.resendBooking);
    },
    async patchCancelBooking({ commit }, { bookingUuid, data }) {
      commit('resetBookingActions');
      await runAsyncFlow(commit, {
        request: patchCancelBooking,
        params: [bookingUuid, data],
        scope: scopes.cancellingActions,
      });

      commit('setBookingActionFeedback', data.applyFees ? BOOKING_ACTIONS.cancelWithFees : BOOKING_ACTIONS.cancelWithoutFees);
    },
    async postBookingBypassCode({ commit }, bookingUuid) {
      commit('resetBookingActions');
      await runAsyncFlow(commit, {
        request: postBookingBypassCode,
        params: [bookingUuid],
        scope: scopes.bypassCode,
      });
      commit('setBookingActionFeedback', BOOKING_ACTIONS.sendBypassCode);
    },
    async patchTerminateBooking({ commit }, bookingUuid) {
      commit('resetBookingActions');
      await runAsyncFlow(commit, {
        request: patchTerminateBooking,
        params: [bookingUuid],
        scope: scopes.finishingActions,
      });
      commit('setBookingActionFeedback', BOOKING_ACTIONS.terminateAsUser);
    },
    async patchTerminateByAdminBooking({ commit }, bookingUuid) {
      commit('resetBookingActions');
      await runAsyncFlow(commit, {
        request: patchTerminateByAdminBooking,
        params: [bookingUuid, 'v2'],
        scope: scopes.finishingActions,
      });
      commit('setBookingActionFeedback', BOOKING_ACTIONS.terminateAsAdmin);
    },
    async patchFinishUnavailability({ commit }, unavailabilityUuid) {
      commit('resetBookingActions');
      await runAsyncFlow(commit, {
        request: patchFinishUnavailability,
        params: [unavailabilityUuid],
        scope: scopes.cancellingActions,
      });
      commit('setBookingActionFeedback', BOOKING_ACTIONS.finishUnavailability);
    },
    async putEditUnavailability({ commit }, { unavailabilityUuid, data }) {
      await runAsyncFlow(commit, {
        request: updateUnavailability,
        params: [unavailabilityUuid, data],
      });
    },
    async deleteUnavailability({ commit }, unavailabilityUuid) {
      commit('resetBookingActions');
      await runAsyncFlow(commit, {
        request: deleteUnavailability,
        params: [unavailabilityUuid],
        scope: scopes.cancellingActions,
      });
      commit('setBookingActionFeedback', BOOKING_ACTIONS.cancelUnavailability);
    },
    async forceFinishBooking({ commit }, bookingUuid) {
      commit('resetBookingActions');
      await runAsyncFlow(commit, {
        request: patchForceFinishBooking,
        params: [bookingUuid],
        scope: scopes.forceFinish,
      });
      commit('setBookingActionFeedback', BOOKING_ACTIONS.forceFinish);
    },
  },
})));

export default planningEditModel;
