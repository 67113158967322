import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { clearErrors } from '@/utils';
export const scopes = {
  newVehicle: 'newVehicle',
};
const { postVehicle } = backOffice.csfleet;
const vehiclesModule = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  actions: {
    async postVehicle({ commit }, vehicle) {
      await runAsyncFlow(commit, {
        request: postVehicle,
        params: [vehicle],
        scope: scopes.newVehicle,
      });
    },
  },
  mutations: { clearErrors },
}));
export default vehiclesModule;
